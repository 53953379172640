@import "helpers";
@import "home";

@font-face {
  font-family: 'Sporting Grotesque_Regular';
  src:    url('../fonts/Regular/Sporting_Grotesque-Regular_web.eot');
  src:    url('../fonts/Regular/Sporting_Grotesque-Regular_web.eot?#iefix') format('embedded-opentype'),
          url('../fonts/Regular/Sporting_Grotesque-Regular_web.woff') format('woff'),
          url('../fonts/Regular/Sporting_Grotesque-Regular_web.woff2') format('woff2'),
          url('../fonts/Regular/Sporting_Grotesque-Regular_web.ttf') format('truetype'),
          url('../fonts/Regular/Sporting_Grotesque-Regular_web.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sporting Grotesque_Bold';
  src:    url('../fonts/Bold/Sporting_Grotesque-Bold_web.eot');
  src:    url('../fonts/Bold/Sporting_Grotesque-Bold_web.eot?#iefix') format('embedded-opentype'),
          url('../fonts/Bold/Sporting_Grotesque-Bold_web.woff') format('woff'),
          url('../fonts/Bold/Sporting_Grotesque-Bold_web.woff2') format('woff2'),
          url('../fonts/Bold/Sporting_Grotesque-Bold_web.ttf') format('truetype'),
          url('../fonts/Bold/Sporting_Grotesque-Bold_web.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: Sporting Grotesque_Regular !important;
}

body {
    background-color: #F1E1D4;
}

.Contact {
    .cta {
        top: 50%;
        right: 70%;
    }

    .contact-form {
        top: 15%;
        right: 40%;
    }

    @media (min-width: 768px) {
        .leave-a-note {
            top: 50%;
            right: 70%;
        }
    }
}

.Work {
    .viewer {
        scroll-snap-type: y mandatory;

        .slide {
            scroll-snap-align: start;
            scroll-snap-stop: normal;
        }
    }

    .experience-box {
        // height: 60vh;
    }

    #container {
        display: flex;
        flex-direction: column;
        position: absolute;
        overflow: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .page {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        color: #000;
        background-color: #e6e6e6;
      }
      .first-page {
        height: 95%;
        min-height: 95%;
      }
      .second-page {
        height: 90%;
        min-height: 90%;
      }
      .third-page {
        height: 90%;
        min-height: 90%;
      }
      .fourth-page {
        height: 95%;
        min-height: 95%;
      }
      .hint {
        position: absolute;
        bottom: 20%;
        font-size: 16px;
      }
}